<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        label="Nombre*"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="formData.name"
          placeholder="Nombre"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Tipo de ubicación*"
        label-for="location_type_id"
      >
        <v-select
          id="location_type_id"
          v-model="formData.location_type_id"
          label="name"
          placeholder="Seleccione tipo"
          class="w-100"
          :options="locationTypes"
          :reduce="option => option.id"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        label="Tipo de dispositivo*"
        label-for="device_type_id"
      >
        <v-select
          id="device_type_id"
          v-model="formData.device_type_id"
          label="name"
          placeholder="Seleccione tipo"
          class="w-100"
          :options="deviceTypes"
          :reduce="option => option.id"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        label="MAC*"
        label-for="mac"
      >
        <b-form-input
          id="mac"
          v-model="formData.mac"
          placeholder="MAC"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      class="text-center"
    >
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        :disabled="isBusy || $v.$invalid"
        @click="saveData"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  inject: ['locationsRepository', 'devicesRepository'],
  components: {
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        device_type_id: null,
        mac: '',
        location_type_id: '',
      },
      locationTypes: [],
      deviceTypes: [],
    }
  },
  validations: {
    formData: {
      name: {
        required,
      },
      location_type_id: {
        required,
      },
      device_type_id: {
        required,
      },
      mac: {
        required,
      },
    },
  },
  async mounted() {
    const me = this
    await me.loadLocationType()
    await me.loadDeviceType()
    if (me.record) me.formData = { ...me.record }
  },
  methods: {
    async loadLocationType() {
      const me = this
      try {
        const res = await me.locationsRepository.getLocationTypeAll()
        me.locationTypes = res.data
      } catch (error) {
        // console.log(error)
      }
    },
    async loadDeviceType() {
      const me = this
      try {
        const res = await me.devicesRepository.getDeviceTypeAll()
        me.deviceTypes = res.data
      } catch (error) {
        // console.log(error)
      }
    },
    saveData() {
      this.$emit('save', this.formData)
    },
  },
}
</script>

<style lang="scss"></style>
