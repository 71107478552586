<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          label="Nombre*"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="formData.name"
            placeholder="Nombre"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          :disabled="isBusy || $v.$invalid"
          @click="save"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          <v-icon
            v-else
            name="save"
          />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BFormGroup,
  BFormInput,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        name: '',
      },
    }
  },
  mounted() {
    const me = this
    if (me.record) {
      me.formData = { ...me.record }
    }
  },
  validations: {
    formData: {
      name: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.formData)
    },
  },
}
</script>

<style lang="scss"></style>
