<template>
  <div class="container_subareas">
    <div v-if="subareas.length > 0">
      <b-card
        v-for="(item, index) in subareas"
        :id="item.id"
        :key="index"
        no-body
        class="card__subarea"
      >
        <b-row class="align-items-center">
          <div
            class="wrapper"
            @click="listDevices(item)"
          />
          <b-col
            cols="3"
            class="title2 pr-0 text-center"
          >
            {{ item.name }}
          </b-col>
          <b-col
            cols="6"
          >
            <div
              class="text-center"
            >
              <div class="title2">
                Dispositivos asociados
              </div>
              <b-badge variant="warning">
                1
              </b-badge>
            </div>
            <!-- <div v-else>
                <b-button
                  variant="success"
                  class="btn_asso"
                >
                  Asociar dispositivos
                </b-button>
              </div> -->
          </b-col>
          <b-col
            cols="3"
            class="m-0 btn__content d-flex"
          >
            <b-button
              v-b-modal.subareadevices
              v-b-tooltip.hover.top="'Editar Subarea'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon rounded-circle mr-25"
              @click="edit(item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            <b-button
              v-b-modal.subareatrash
              v-b-tooltip.hover.top="'Eliminar Subarea'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              class="btn-icon rounded-circle"
              @click="deleteSubarea(item)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div
      v-else
      class="p-2"
    >
      <div class="box p-1">
        No existen subareas registradas en esta area.
      </div>
    </div>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
  BButton,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
	},
  components: {
		BCard,
    BButton,
    BRow,
    BCol,
    BBadge,
	},
  props: {
    subareas: {
      type: Array,
      default: null,
    },
  },
  methods: {
    edit(item) {
      this.$emit('editSubarea', item)
    },
    deleteSubarea(item) {
      this.$emit('deleteSubarea', item)
    },
    listDevices(item) {
      const card = document.querySelector('.active')
      const cardId = document.getElementById(item.id)

      if (card) card.classList.remove('active')
      cardId.classList.add('active')

      this.$emit('listDevices', item)
    },
  },
}
</script>

<style lang="scss" scoped>
  .title2 {
    font-size: 12px;
    color: #636363;
    font-weight: bold;
  }

  .container_subareas {
    padding: 1rem;
    height: 250px;
    overflow-y: scroll;
  }

  .card__subarea {
    border: 2px solid rgb(121, 117, 117);
    padding: .7rem;
    border-radius: .3rem;
    margin-bottom: 12px;
  }

  .wrapper {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 60px;
  }

  .btn__content {
    position: absolute;
    right: 10px;
    z-index: 4;
  }

  .card__subarea.active {
    border: 2px solid #7367F0;
  }

  .btn_asso {
    padding: 10px;
    position: absolute;
  }

  .box {
    border: 1.5px solid #EA5455;
    color: #EA5455;
    font-weight: bold;
    font-size: 14px;
  }
</style>
