<template>
  <div>
    <b-card
      no-body
      class="card__"
    >
      <div class="header">
        Listado de Dispositivos - {{ subareaName }}
      </div>
      <div class="container_location">
        <base-actions-table
          :columns="fields"
          :records="records"
          :busy="isBusy"
          :module="module"
          @creating="handleCreating"
          @editing="handleEditing"
          @deleting="handleDeleting"
        />
      </div>
    </b-card>
    <b-modal
      id="modal-associate-device"
      centered
      title="Asociar dispositivo"
      hide-footer
    >
      <form-associate-device
        :record="record"
        :is-busy="isBusy2"
        @save="saveData"
      />
    </b-modal>
  </div>
</template>

<script>
import {
	BCard,
  BModal,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import BaseActionsTable from '@/components/base/BaseActionsTable'
import FormAssociateDevice from './FormAssociateDevice'

export default {
  inject: ['locationsRepository', 'devicesRepository'],
	components: {
		BCard,
    BModal,
    BaseActionsTable,
    FormAssociateDevice,
	},
  props: {
    subareaId: {
      type: String,
      default: null,
    },
    customerId: {
      type: String,
      default: null,
    },
    subareaName: {
      type: String,
      default: null,
    },
  },
  data() {
    const me = this
		return {
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'location_Type.name',
          label: 'Tipo de Ubicación',

        },
        {
          key: 'device.deviceType.name',
          label: 'Tipo de Dispositivo',

        },
        {
          key: 'device.mac',
          label: 'Mac Dispositivo',
        },
        {
          key: 'actions',
          label: 'Acciones',
        },
      ],
      records: [],
      isBusy: false,
      isBusy2: false,
      module: 'areadevice',
      record: null,
      brandId: this.$route.params.brandId,
		}
	},
  watch: {
    subareaId(id) {
      this.loadDevices(id)
    },
  },
  mounted() {
    this.loadDevices(this.subareaId)
  },
  methods: {
    handleCreating() {
      this.record = null
			this.$bvModal.show('modal-associate-device')
		},

		handleEditing(record) {
      this.record = {
        id: record.id,
        name: record.name,
        device_type_id: record.device ? record.device.device_type_id : null,
        mac: record.device ? record.device.mac : '',
        location_type_id: record.location_type_id,
      }
			this.$bvModal.show('modal-associate-device')
		},

		handleDeleting(record) {
			const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
            me.isBusy = true
						await me.locationsRepository.delete(record.subarea_id, record.id)
						me.loadDevices(record.subarea_id)
            me.isBusy = false
					}
				})
		},

    async saveData(formData) {
      const me = this
      try {
        me.isBusy2 = true
        const uuid = formData.id ? formData.id : uuidv4()
        const option = formData.id ? 'update' : 'save'

        const data = {
          name: formData.name,
          location_type_id: formData.location_type_id,
        }

        await me.locationsRepository[option](
          me.subareaId,
          uuid,
          data,
        )

        const device = {
          mac: formData.mac,
          device_type_id: formData.device_type_id,
          location_id: uuid,
          brand_id: me.brandId,
          customer_id: me.customerId,
          status: '1',
        }

        await me.devicesRepository[option](
          uuid,
          device,
        )
        me.loadDevices(me.subareaId)
        me.$bvModal.hide('modal-associate-device')

				me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
      } catch (error) {
        me.$bvToast.toast('Registro no guardado.', {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
        // console.log(error)
      } finally {
        me.isBusy2 = false
      }
    },

    async loadDevices(id) {
      const me = this
      try {
        me.isBusy = true
        const res = await me.locationsRepository.getAll(id)
        me.records = res.data
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBusy = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .container_location {
    height: 450px;
    overflow-y: scroll;
  }
</style>
