<template>
  <div>
    <b-card
      no-body
      class="card__"
    >
      <div v-if="isBusy">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Cargando datos...</strong>
        </div>
      </div>
      <div v-else>
        <div v-if="areas.length > 0">
          <div
            v-for="(item, index) in areas"
            :key="index"
            class="mb-1"
          >
            <div
              class="header"
            >
              <div>
                <span class="title_area">
                  {{ item.name }}
                </span>
                <b-badge>
                  {{ item.subareas.length }}
                </b-badge>
              </div>

              <div class="d-flex align-items-center">
                <b-button
                  v-b-tooltip.hover.top="'Nueva subarea'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="success"
                  class="btn-icon rounded-circle mr-25"
                  @click="createSubarea(item.id)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Editar area'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon rounded-circle mr-25"
                  @click="editArea(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Eliminar Area'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon rounded-circle mr-25"
                  @click="deleteArea(item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                  />
                </b-button>
                <div
                  class="btn__chevrondown"
                  @click="collapse(item.id)"
                >
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="2.0x"
                  />
                </div>
              </div>
            </div>
            <b-collapse
              :id="item.id"
              accordion="my-accordion"
              no-body
            >
              <list-subareas
                :subareas="item.subareas"
                @editSubarea="editSubarea"
                @deleteSubarea="deleteSubarea"
                @listDevices="listDevices"
              />
            </b-collapse>
          </div>
        </div>
        <div
          v-else
          class="p-2"
        >
          <div class="box p-1">
            No existen áreas registradas en el servicio seleccionado.
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modal-create-subarea"
      centered
      :title="title"
      hide-footer
    >
      <form-create-edit-subarea
        :is-busy="isBusy2"
        :record="record"
        @save="save"
      />
    </b-modal>
  </div>
</template>

<script>
import {
	BCard,
  BButton,
  VBTooltip,
  BCollapse,
  BBadge,
  BSpinner,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import ListSubareas from './ListSubareas'
import FormCreateEditSubarea from './FormCreateEditSubarea'

export default {
  inject: ['subAreasRepository'],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
	},
	components: {
		BCard,
    BCollapse,
    BButton,
    BBadge,
    BSpinner,
    BModal,
    ListSubareas,
    FormCreateEditSubarea,
	},
  props: {
    areas: {
      type: Array,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this
    return {
      record: null,
      customerId: me.$route.params.customerId,
      brandId: me.$route.params.brandId,
      serviceId: me.$route.params.serviceId,
      areaId: null,
      isBusy2: false,
      isBusy3: false,
      title: 'Nueva Subarea',
    }
  },
  methods: {
    collapse(areaId) {
      this.$root.$emit('bv::toggle::collapse', areaId)
    },
    listDevices(asso) {
      this.$emit('listDevices', asso)
    },
    editArea(item) {
      this.$emit('editArea', item)
    },
    deleteArea(id) {
      this.$emit('deleteArea', id)
    },
    createSubarea(id) {
      const me = this
      me.areaId = id
      me.record = null
      me.title = 'Nueva Subarea'
      me.$bvModal.show('modal-create-subarea')
    },
    editSubarea(item) {
      const me = this
      me.title = 'Actualizar Subarea'
      me.record = item
      me.areaId = item.area_id
      me.$bvModal.show('modal-create-subarea')
    },
    async save(data) {
      const me = this
      try {
        me.isBusy2 = true
        const uuid = data.id ? data.id : uuidv4()
        const option = data.id ? 'update' : 'save'
        await me.subAreasRepository[option](
          me.areaId,
          uuid,
          data,
        )
        const subarea = {
          ...data,
          area_id: me.areaId,
          id: uuid,
          name: data.name,
        }

        me.areas.forEach(el => {
          if (el.id == me.areaId) {
            el.subareas.forEach((elem, index) => {
              if (elem.id == uuid) {
                el.subareas.splice(index, 1)
                el.subareas.push(subarea)
              }
            })
            if (option == 'save') el.subareas.push(subarea)
          }
        })
        me.$bvModal.hide('modal-create-subarea')
        me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
      } catch (error) {
        me.$bvToast.toast('Registro no guardado.', {
          title: 'Ocurrió un error al guardar el registro',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
          appendToast: true,
        })
        // console.log(error)
      } finally {
        me.isBusy2 = false
      }
    },
    async deleteSubarea(item) {
      const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
						try {
							await me.subAreasRepository.delete(
                item.area_id,
                item.id,
							)
              me.areas.forEach(el => {
                if (el.id == item.area_id) {
                  el.subareas.forEach((elem, index) => {
                    if (elem.id == item.id) {
                      el.subareas.splice(index, 1)
                    }
                  })
                }
              })
              me.$bvToast.toast('Registro eliminado con éxito', {
                title: 'Éxito',
                toaster: 'b-toaster-bottom-right',
                variant: 'success',
                solid: true,
                appendToast: true,
              })
						} catch (e) {
							console.error(e)
							me.$bvToast.toast('Error', {
								title: 'Ocurrió un error al eliminar el registro',
								toaster: 'b-toaster-bottom-right',
								variant: 'danger',
								solid: true,
								appendToast: true,
							})
						}
					}
				})
    },
  },
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 16px;
    color: #636363;
    font-weight: bold;
  }

  .card__ {
    box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
  }

  .header {
    color: #ececec;
    background-color: #7367f0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn__chevrondown {
    color: white;
  }

  .title_area {
    font-size: 16px;
    color: white;
    font-weight: bold;
  }

  .box {
    border: 1.5px solid #EA5455;
    color: #EA5455;
    font-weight: bold;
    font-size: 14px;
  }
</style>
