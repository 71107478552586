<template>
  <div>
    <div class="container container2">
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <div class="title2">
            {{ headquarter.name }}
          </div>
        </b-col>
        <b-col
          cols="5"
          class="d-flex align-items-center"
        >
          <label class="title mr-1"> Servicio </label>
          <v-select
            v-model="selected"
            label="name"
            placeholder="Seleccione servicio"
            class="w-100"
            :options="options"
            :reduce="(option) => option.id"
            :disabled="isDisabled"
            @input="setSelected"
          />
        </b-col>
        <b-col cols="2">
          <b-button
            variant="success"
            @click="handleCreating"
          >
            Nueva Area
          </b-button>
        </b-col>
        <!-- <b-col
          cols="5"
          class="d-flex align-items-center"
        >
          <label class="title mr-1">Buscar</label>
          <b-form-input
            type="search"
            placeholder="Mac, Tipo Dispositivo"
            class="search"
          />
        </b-col> -->
      </b-row>
    </div>
    <b-row class="mb-2">
      <b-col cols="5">
        <list-areas
          :areas="areas"
          :is-busy="isBusy"
          @editArea="handleEditing"
          @deleteArea="handleDeleting"
          @listDevices="listDevices"
        />
      </b-col>
      <b-col
        v-if="isVisible"
        cols="7"
      >
        <list-devices
          :subarea-id="subareaId"
          :customer-id="customerId"
          :subarea-name="subareaName"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modal-create-area"
      centered
      :title="title"
      hide-footer
    >
      <form-create-edit-area
        :is-busy="isBusy2"
        :record="record"
        @save="save"
      />
    </b-modal>
  </div>
</template>

<script>
import {
 BRow, BCol, BButton, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import ListAreas from '../components/ListAreas'
import ListDevices from '../components/ListDevices'
import FormCreateEditArea from '../components/FormCreateEditArea'

export default {
	inject: [
		'headquarterRepository',
		'areasRepository',
		'servicesRepository',
	],
	components: {
		BRow,
		BCol,
		BButton,
		BModal,
		vSelect,
		ListAreas,
		ListDevices,
		FormCreateEditArea,
	},
	data() {
		const me = this
		return {
			selected: null,
			options: [],
			areas: [],
			record: null,
			isVisible: false,
			customerId: me.$route.params.customerId,
			brandId: me.$route.params.brandId,
			serviceId: me.$route.params.serviceId,
			areaId: null,
			subareaId: null,
			isBusy: false,
			isBusy2: false,
			headquarter: {},
			isDisabled: true,
			title: 'Nueva area',
			subareaName: null,
		}
	},
	async mounted() {
		const me = this
		await me.loadHeadquarter()
		await me.loadServices()
		await me.loadAreas()
	},
	methods: {
		listDevices(item) {
			const me = this
			me.subareaName = item.name
			me.subareaId = item.id
			me.isVisible = true
		},

		async loadHeadquarter() {
			const me = this
			try {
				me.headquarter = await me.headquarterRepository.find(
					me.customerId,
					me.brandId,
				)
			} catch (error) {
				// console.log(error)
			}
		},

		handleCreating() {
			const me = this
			me.title = 'Nueva area'
			me.record = null
			this.$bvModal.show('modal-create-area')
		},

		handleEditing(item) {
			const me = this
			me.title = 'Actualizar area'
			me.record = item
			this.$bvModal.show('modal-create-area')
		},

		async save(data) {
			const me = this
			try {
				me.isBusy2 = true
				const uuid = data.id ? data.id : uuidv4()
				const option = data.id ? 'update' : 'save'
				await me.areasRepository[option](me.serviceId, uuid, data)
				me.loadAreas()
				me.$bvModal.hide('modal-create-area')
				me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
			} catch (error) {
				me.$bvToast.toast('Registro no guardado.', {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
				// console.log(error)
			} finally {
				me.isBusy2 = false
			}
		},

		async handleDeleting(id) {
			const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
						me.isBusy = true
						try {
							await me.areasRepository.delete(me.serviceId, id)
							me.loadAreas()
						} catch (e) {
							console.error(e)
							me.$bvToast.toast('Error', {
								title: 'Ocurrió un error al eliminar el registro',
								toaster: 'b-toaster-bottom-right',
								variant: 'danger',
								solid: true,
								appendToast: true,
							})
						}
					}
				})
		},

		async loadAreas() {
			const me = this
			try {
				me.isBusy = true
				const res = await me.areasRepository.getAll(me.serviceId)
				me.areas = res.data
			} catch (error) {
				me.areas = []
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},

		async loadServices() {
			const me = this
			try {
				me.isDisabled = true
				const res = await me.servicesRepository.getAll(me.brandId)
				me.options = res.data
				me.selected = me.serviceId
			} catch (error) {
				me.options = []
				me.selected = null
				// console.log(error)
			} finally {
				me.isDisabled = false
			}
		},

		setSelected(id) {
			const me = this
			me.serviceId = id
			me.$router.push({
				name: 'areas',
				params: {
					customerId: me.customerId,
					brandId: me.brandId,
					serviceId: me.serviceId,
				},
			})
			me.loadAreas()
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.title {
	font-size: 16px;
	color: #636363;
	font-weight: bold;
}

.title2 {
	font-size: 22px;
	color: #636363;
	font-weight: bold;
}

.card__ {
	box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
		0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.header {
	background-color: #7367f0;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	color: white;
	font-weight: bold;
}

.container2 {
	border: 4px solid #7367f0;
	padding: 15px;
	border-radius: 0.6rem;
	margin-bottom: 20px;
	background-color: white;
}
</style>
